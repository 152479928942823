"use strict";

import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import { createApp } from "vue";

import App from "@/App.vue";
import router from "@/router.js";
import store from "@/store.js";

import "@/assets/styles/common.scss";

const app = createApp(App);

app.use(VCalendar, {});
app.use(router);
app.use(store);
app.mount("#app");
