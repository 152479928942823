"use strict";

import { createStore } from "vuex";

export default createStore({
    "strict": process.env.NODE_ENV !== "production",
    "state": {
        "transceiver": {},
    },
    "getters": {
        "getTransceiver": state => state.transceiver,
    },
    "actions": {
        setTransceiver({ commit }, data) {
            commit("setTransceiver", data);
        },
    },
    "mutations": {
        setTransceiver(state, data) {
            state.transceiver = data;
        },
    },
});
